.u-link {
    color: rgb(94, 140, 226);
    cursor: pointer;
}
.modal-overlay {
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: opacity .2s ease;
    z-index: 1000;
}

.modal-overlay.active {
    display: flex;
}

.modal-overlay.visible {
    opacity: 1;
}

.modal-container {
    flex-basis: 50%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 3px;
    position: fixed;
    top: 10%;
}

.modal-header {
    display: flex;
    font-weight: bold;
}

.modal-close {
    margin-left: auto;
    color: inherit;
    text-decoration: none;
    margin-top: -.5rem;
    font-size: 2rem;
}

.modal-content {
    height: 600px;
    overflow: auto;
    padding: 20px;
    width: 840px;
}

.preWrap {
    white-space:pre-wrap;
}
