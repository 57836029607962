/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 19, 2021 */



@font-face {
    font-family: 'gorditablack';
    src: url('gordita_black-webfont.woff2') format('woff2'),
         url('gordita_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gorditabold';
    src: url('gordita_bold-webfont.woff2') format('woff2'),
         url('gordita_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gorditalight';
    src: url('gordita_light-webfont.woff2') format('woff2'),
         url('gordita_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gorditamedium';
    src: url('gordita_medium-webfont.woff2') format('woff2'),
         url('gordita_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gorditaregular';
    src: url('gordita_regular-webfont.woff2') format('woff2'),
         url('gordita_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}